import React from "react"
import { graphql, StaticQuery, Link } from "gatsby"
import alexcovologo from "../../content/assets/alexcovo-logo.png"
import mwbe from "../../content/assets/mwbe.png"
import dronepilot from "../../content/assets/dronepilot.png"


import Layout from "../components/layout"
import SEO from "../components/seo"
import PostCard from "../components/postCard"

// import "../utils/global.scss"
import "../utils/normalize.css"
import "../utils/css/screen.css"
//TODO: switch to staticQuery, get rid of comments, remove unnecessary components, export as draft template
const BlogIndex = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges
  let postCounter = 0

  return (
    <Layout title={siteTitle}>
      <SEO
        title="M/WBE Certified Advertising/Photo/Video: Alex Covo Studio NYC "
        keywords={[`photographer`, `videographer`, `advertising`,  `nyc`,  `fashion-photographer`,  `lifestyle`,  `art`,  `web-development`,  `ai`, `artificial intellegence`, `gatsby`, `javascript`, `react`]}
      />

      {data.site.siteMetadata.description && (
        <header className="page-head">
                    <Link
                to={`/services`}
                title="ALEX COVO STUDIO NYC"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={alexcovologo} alt="alex covo studio nyc"></img>
          </Link> 
          <h2 className="page-head-title">
            {data.site.siteMetadata.description}
          </h2>

 {/*           <Link
                to={`https://www1.nyc.gov/site/sbs/businesses/subcontractors-resource-guide.page`}
                title="M/WBE"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={mwbe} alt="M/WBE"></img>
          </Link> 

       <img src={mwbe} alt="M/WBE"></img>
       <img src={dronepilot} alt="FAA Part 107 Remote Pilot Certified"></img>
 */}
        </header>
      )}

      <div className="post-feed">
        {posts.map(({ node }) => {
          postCounter++
          return (
            <PostCard
              key={node.fields.slug}
              count={postCounter}
              node={node}
              postClass={`post`}
            />
          )
        })}
      </div>


      <article className="post-content page-template no-image">
        <div className="post-content-body">

{/*
        <figure className="kg-card kg-image-card">
            <Img
              fluid={data.benchAccounting.childImageSharp.fluid}
              className="kg-image"
            />

            <figcaption>We love our logo. Inspired by the grit and texture of NYC.</figcaption>
          </figure>

          <h2 id="Image-is-Everything">
            Image is Everything. 
          </h2>
*/}

        

 {/*       
          <h3 id="capabilities">CAPABILITIES</h3>
          <p>
            We're all about passion.
          </p>
          <p>
            When we started our professional journey we never imagined the places we would travel to, and the different cultures and experiences
            we have have. Our commitment to excellence and our passion for story telling has entrenched itself in our DNA. We will always treat you
            as our one and only customer and partner. 
          </p>
*/}
          <h3 id="unordered">
            <strong>
              <strong>What We Do</strong>
            </strong>
          </h3>
          <ul>
            <li>Advertising, Campaigns, Lookbooks, e-Commerce, Production, Creative Direction</li>
            <li>Photography - Commercial, Editorial, Portraits, Art, Events</li>
            <li>Video Production - Directing, Filming, Capture, Encoding/transcoding, Editing, Workflow, Online Distribution</li>
            <li>Social Media - Content Development, Media Integration</li>
            <li>Web Development - JamStack, Static Site, PWA, CMS, CDN Distribution </li>
          </ul>
        </div>
      </article>






    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    benchAccounting: file(
      relativePath: { eq: "alexcovo-logo.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 250) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 1360) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <BlogIndex location={props.location} props data={data} {...props} />
    )}
  />
)
